import React, { useState } from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/form/form.css"

const Unsubscribe = () => {
  const [token, setToken] = useQueryParam("token", StringParam)
  const [allowSubmit, setSubmit] = useState(true)
  const [formData, updateFormData] = useState({
    email: "",
  })
  const [formStatus, setFormStatus] = useState("")

  const submitForm = form => {
    const data = new FormData()
    data.append("token", token)
    data.append("message", formData.message)
    data.append("form-name", "unsubscribe")

    setSubmit(false)

    fetch("/", {
      method: "POST",
      body: data,
    })
      .then(res => {
        setSubmit(true)
        if (res.ok) {
          setFormStatus("SUBMITTED")
        } else {
          setFormStatus("FAILED")
        }
      })
      .catch(error => {
        setSubmit(true)

        setFormStatus("FAILED")
      })
  }

  if (!token) {
    return <Layout>You seemed to have performed an invalid action!</Layout>
  }

  if (formStatus === "SUBMITTED") {
    return (
      <Layout>
        <div id="form-box">
          Sad to see you go{" "}
          <span aria-label="sad" role="img">
            😔
          </span>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO title="Unsubscribe" />
      <div id="form-box">
        <p>Are you sure?</p>
        <form
          name="unsubscribe"
          method="POST"
          data-netlify="true"
          onSubmit={e => {
            e.preventDefault()
            submitForm()
          }}
        >
          <Container>
            <Row>
              <Col>
                <textarea
                  placeholder="I read every message, so your feedback is appreciated."
                  onChange={e => {
                    updateFormData({
                      ...formData,
                      message: e.target.value,
                    })
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <button type="submit" disabled={!allowSubmit}>
                  Yes
                </button>
              </Col>
            </Row>
          </Container>
        </form>
        {formStatus === "FAILED" ? (
          <div>
            {" "}
            Something went wrong! Please try again!{" "}
            <span aria-label="disappointed" role="img">
              😞
            </span>{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    </Layout>
  )
}

export default Unsubscribe
